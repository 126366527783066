import { createSignal } from 'solid-js';
import { EventEmitter, eventEmitter } from '../eventEmitter';
import { UseCase } from '../useCase';

function useUseCase<T>(useCaseClass: new (eventEmitter?: EventEmitter, onProgress?: (value: number) => void) => UseCase<T>) {
  const [isLoading, setIsLoading] = createSignal(false);
  const [progress, setProgress] = createSignal(0);
  const [didSucceed, setDidSucceed] = createSignal(false);
  const useCase = new useCaseClass(eventEmitter, setProgress);

  const execute = async (params?: any) => {
    try {
      setIsLoading(true);
      setDidSucceed(false);
      await useCase.execute(params);
      setDidSucceed(true);
    } catch (error) {
      eventEmitter?.emitError(error as Error);
      setDidSucceed(false);
    } finally {
      setIsLoading(false);
    }
  };
  return { execute, isLoading, didSucceed, progress };
}

export default useUseCase;
