import { EventEmitter } from './eventEmitter';

export abstract class UseCase<T> {
  protected static state: any;
  protected eventEmitter?: EventEmitter;
  protected onProgress?: (progress: number) => void;

  private static runningUseCases = new Map<string, Promise<void>>();
  private static initialStatePromise?: Promise<void>;

  constructor(eventEmitter?: EventEmitter, onProgress?: (progress: number) => void) {
    this.eventEmitter = eventEmitter;
    this.onProgress = onProgress;
  }

  private getExecutionKey(params: any): string {
    const paramsKey = JSON.stringify(params);
    return `${this.constructor.name}_${paramsKey}`;
  }

  public async execute(params?: any): Promise<void> {
    if (!this.isAppStateInitialized() && !UseCase.initialStatePromise) {
      UseCase.initialStatePromise = this.initializeState().then((state) => {
        UseCase.state = state;
      });
    }
    await this.ensureStateInitialized();
    const executionKey = this.getExecutionKey(params);
    if (UseCase.runningUseCases.has(executionKey)) {
      await UseCase.runningUseCases.get(executionKey);
      return;
    }
    const executionPromise = this.runWithUpdate(() => this.runLogic(params));
    UseCase.runningUseCases.set(executionKey, executionPromise);
    try {
      await executionPromise;
    } finally {
      UseCase.runningUseCases.delete(executionKey);
    }
  }

  private async ensureStateInitialized(): Promise<void> {
    if (!this.isAppStateInitialized()) {
      await UseCase.initialStatePromise;
    }
  }

  protected getState(): T {
    return UseCase.state;
  }

  protected navigate(url: string) {
    this.eventEmitter?.emitNavigation(url);
  }

  protected abstract isAppStateInitialized(): boolean;
  protected abstract runLogic(params: any): Promise<void>;
  protected abstract initializeState(): Promise<T>;

  protected async runWithUpdate(functionToRun: () => Promise<void>): Promise<void> {
    await functionToRun();
    this.eventEmitter?.emitStateChange(UseCase.state);
  }
}
