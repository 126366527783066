export enum RentalApplicationSteps {
  APPLICANT_INFORMATION = 'applicant-information',
  POTENTIAL_UNITS = 'potential-units',
  RENTAL_HISTORY = 'rental-history',
  WORK_HISTORY = 'employment-income',
  QUESTIONS = 'rental-questions',
  TERMS_AND_CONDITIONS = 'terms-and-conditions',
  PAYMENT = 'payment',
  TRANS_UNION_SCREENING = 'transunion-connection',
  TRANS_UNION_QUESTIONS = 'questionnaire',
  APPLICATION_SUBMITTED = 'application-submitted',
  MANUAL_VERIFICATION = 'manual-verification',
}
